<template>
  <div class="curationDateInfo">
    <div v-if="dateRangeType !== 'hourMs'">
      {{ formattedDate(_.get(article, 'curation.dateMs')) }}
      <div class="local-time">local: {{ formattedDate(_.get(article, 'curation.dateMs'), true) }}</div>
      <div v-if="spotlightId && _.get(article, 'hourMs')" class="spotlightIdCopy">
        <div><b>Trigger Date:</b></div>
        {{ formattedDate(_.get(article, 'hourMs')) }}
      </div>
    </div>
    <div v-if="dateRangeType === 'hourMs'">
      <div v-if="spotlightId && _.get(article, 'hourMs')">
        {{ formattedDate(_.get(article, 'hourMs')) }}
      </div>
      <div v-if="_.get(article, 'curation.dateMs')" class="curationDate">
        <div><b>Curation Date:</b></div>
        {{ formattedDate(_.get(article, 'curation.dateMs')) }}
      </div>
    </div>

    <div v-if="spotlightId" class="spotlightIdCopy">
      <div class="label">Spotlight Id:</div>
      <buttonCopy :value="spotlightId" />
    </div>
    <div v-if="showDocumentHash">
      <documentHashCopy :document-hash="article.documentHash" />
    </div>
    <div v-if="_.get(article, 'curation.curatorId')" class="curator">
      <span>curator: </span
      ><span class="email">{{
        _.get(article, 'curation.curatorId') === currentUser.id ? 'me' : _.get(article, 'curation.curatorEmail')
      }}</span>
    </div>
    <div v-if="_.get(article, 'curation.count') > 1" class="modified-curation-icon">
      <i class="fas fa-chalkboard-teacher" :title="'modified curation: ' + article.curation.count" />
    </div>
    <div v-if="_.get(previousCuration, 'curatorEmail')" class="previous-curator">
      <span>previous curator: </span
      ><span class="email">{{
        _.get(previousCuration, 'curatorId') === currentUser.id ? 'me' : _.get(previousCuration, 'curatorEmail')
      }}</span>
      <div class="previous-curation-excluded">
        <i
          class="fa"
          :class="previousCuration.excluded || previousCuration.rejected ? 'fa-times-circle' : 'fa-check-circle'"
        />
        {{ previousCuration.curationType }}
      </div>
      <div v-if="previousCuration.exclusionReasons" class="previous-curation-excluded">
        exclusionReasons:{{ previousCuration.exclusionReasons }}
      </div>
      <div v-if="previousCuration.dpv2" class="previous-curation-v2">
        v2:
        <ul
          v-for="(tag, tagIndex) in previousCuration.dpv2.associationTags"
          :key="`v2${tag._id}_${tagIndex}`"
          class="list-group"
        >
          <li
            class="list-group-item border"
            :class="{ 'border-danger': tag.excluded, 'border-success': !tag.excluded }"
          >
            <div :class="{ 'text-danger': tag.excluded, 'text-success': !tag.excluded }">
              <i class="fa" :class="tag.excluded ? 'fa-times-circle' : 'fa-check-circle'" />
              {{ tag.excluded ? 'excluded' : 'ingested' }}
            </div>
            <buttonCopy :value="tag.longName" />
            <buttonCopy :value="tag.tvlId" />
          </li>
        </ul>
      </div>
      <div v-if="previousCuration.dpv3" class="previous-curation-v3">
        v3:
        <ul
          v-for="(tag, tagIndex) in previousCuration.dpv3.associationTags"
          :key="`v3${tag._id}_${tagIndex}`"
          class="list-group"
        >
          <li
            class="list-group-item border"
            :class="{ 'border-danger': tag.excluded, 'border-success': !tag.excluded }"
          >
            <div :class="{ 'text-danger': tag.excluded, 'text-success': !tag.excluded }">
              <i class="fa" :class="tag.excluded ? 'fa-times-circle' : 'fa-check-circle'" />
              {{ tag.excluded ? 'excluded' : 'ingested' }}
            </div>
            <buttonCopy :value="tag.longName" />
            <buttonCopy :value="tag.tvlId" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import xcomputed from '../js/xcomputed'
import xlibs from '../js/xlibs'
import documentHashCopy from '../components/documentHashCopy'
import buttonCopy from '../components/buttons/buttonCopy'

export default {
  name: 'CurationDateInfo',
  components: {
    buttonCopy,
    documentHashCopy
  },
  props: {
    article: {
      type: Object,
      default: null
    },
    showDocumentHash: {
      type: Boolean,
      default: true
    },
    spotlightId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      currentUser: {}
    }
  },
  computed: {
    ...xcomputed,
    ...mapState({
      dateRangeType: (state) => state.articleFilters.sort.date.dateRangeType
    }),
    previousCuration() {
      const history = _.get(this.article, 'curation.history', [])
      const previousCuration = _.nth(history, -2)
      return previousCuration
    }
  },
  mounted() {
    this.currentUser = xlibs.getUser()
  },
  methods: {
    ...xlibs
  }
}
</script>
